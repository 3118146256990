<template>
  <div class="background">
    <div class="typeArea top">
      <div class="top-header">
        常见问题
      </div>
      <div class="top-content">
        <div class="content-item">
          <div class="item-question">Q：科研人网在技术层面有什么创新？与其它同类网站的区别在哪？</div>
          <div class="item-ask">
            <span>A：</span>以人为本的技术驱动是支撑科研人从众多科研类站点中脱颖而出的最主要因素 。科研人从创立初期便十分重视新兴技术的使用，截至目前，科研人已经应用了包括自然语言处理、机器翻译、个性化推荐等人工智能技术，并运用大数据全栈技术用于分析挖掘海量数据，为用户提供真实所需的信息，极大地提升了用户获取科研信息的效率。
          </div>
        </div>
        <div class="content-item">
          <div class="item-question">Q：科研人网的海量数据是从哪里获取的？有保障吗？</div>
          <div class="item-ask">
            <span>A：</span>科研人的海量数据主要来自于科技部、工信部、教育部等有关部委，各省市政府管理部门，各主要政府采购和招投标网站，以及美国、俄罗斯、英国、德国、日本、韩国等国家的科研基金管理机构的官方网站。由于是从官网采集的一手数据，数据可靠性有保障。此外，科研人有专门的数据处理团队，实时采集和处理海量数据，基于科研人的科学分类方法，赋予每条信息更大的使用价值。
          </div>
        </div>
        <div class="content-item">
          <div class="item-question">Q：为什么要注册会员？对机构用户的解决方案是什么？</div>
          <div class="item-ask">
            <span>A：</span>科研人设置会员体系主要基于三点考虑。一是为用户提供更加个性化的服务，只有基于会员体系，才能实现以人为本的服务；二是满足不同层次用户的实际需求，基于会员体系，所有用户都能各取所需；三是防止爬虫及恶意竞争，保障用户有一个良好的使用体验。针对机构用户，我们提供基于IP地址的访问模式，支持个性化功能开发，有相关需求可与微信客服联系。
          </div>
        </div>
      </div>

    </div>
    <div class="bottom typeArea">
      <div class="bottom-header">意见与建议</div>
      <div class="bottom-content">
        <div class="content-top">HI，亲爱的小伙伴们：</div>
        <div class="content-main">感谢您对科研人（www.keyanpro.com）的支持与关注。请扫描下方二维码添加科研人客服微信，反馈您遇到的问题或意见建议，您的意见对科研人非常重要，是科研人前进的动力。我们虚心接受您最诚挚的意见和建议，对您的宝贵意见我们将第一时间给予解答并修正。</div>
        <div class="content-img">
          <div class="img">
            <img
              src="../../../assets/image/问题与建议/微信客服.png"
              alt=""
            >
          </div>
          <div class="text">微信客服</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
created(){
    this.navxuanzhong()
  },
  methods: {
     navxuanzhong(){
      localStorage.setItem("navActive", 0);
      this.$store.commit("setnavActive", 0);
    },
  }
}
</script>

<style lang="scss" scoped>
// 版心
.background {
  background-color: #f1f2f6;
  overflow: hidden;
}
.typeArea {
  width: 840px;
  margin: 0 auto;
}
.top {
  box-sizing: border-box;
  margin-top: 20px;
  background-color: #fff;
  padding: 60px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .top-header {
    font-size: 35px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #5e068c;
    margin-bottom: 50px;
  }
  .top-content {
    .content-item {
      margin-bottom: 42px;
      .item-question {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #990263;
        margin-bottom: 47px;
      }
      .item-ask {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: #333333;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
.bottom {
  margin-top: 20px;
  background-color: #fff;
  padding: 60px 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 104px;

  .bottom-header {
    font-size: 35px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #5e068c;
  }
  .bottom-content {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 30px;
    color: #333333;
    .content-top {
      line-height: 0px;
      margin-bottom: 48px;
    }
    .content-main {
      margin-bottom: 70px;
    }
    .content-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
